// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, InputLabel, TextField , RadioGroup , FormControlLabel , Radio  } from "@material-ui/core";
import { Formik } from "formik";

import ProcessPaymentController, {
  Props,
} from "./ProcessPaymentController.web";
import { styles } from "./ProcessPaymentStyles.web";
import CommonButtonWeb from "../../../components/src/Button/CommonButton.web";
import {
  handleFormikHelperText,
  handleFormikErrors,
  convertIntoCurrency,
  balanceConverter
} from "../../../components/src/Utils/utils";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomLoader from "../../../components/src/CustomLoader/CustomLoader.web";
import NumberFormatCustom from "../../../components/src/CustomTextField/NumberFormatCustom"
const right = require("../assets/right.png");

class ProcessPayment extends ProcessPaymentController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { payment_received, payment_date, account_id , paymentType, isLoad, activeBtn, tableData, fieldData, company_name, balance , isBalanceCredit,oldestInvoiceDate} = this.state;

    const renderIcon = (obj:any) => {
      const flag = fieldData.payment_received_id === obj.id 
      if(flag) {
      return <img src={right} data-test-id="buttonCLicker" alt="right" onClick={() => this.updatePayment(obj.payment_type)} />}
    }

    return (
      <div className={classes.mainRootDiv}>
        {isLoad && <CustomLoader open={isLoad} />}
        <Typography className="mainPaymentTitle">Payments Received</Typography>
        <Grid container spacing={3} direction={"row"}>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            <h5 className={classes.leftTitle}>{company_name}</h5>
            <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={{
                payment_received: payment_received,
                payment_date: payment_date,
                account_id: account_id,
                payment_type:paymentType
              }}
              validationSchema={this.validationPaymentSchema}
              onSubmit={(values, { resetForm }) => { this.createPayment(values); resetForm() }}
            >
              {({ errors, values, handleSubmit, handleChange, touched , setFieldValue }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} direction={"column"}>
                      <Grid item sm={12} md={12}>
                        {/* @ts-ignore */}
                        <RadioGroup name="payment_type" data-test-id="radio_test" row onChange={(e)=>setFieldValue("payment_type",e.target.value, this.handleRadioCheck(e.target.value))} value={values.payment_type}>
                          <FormControlLabel
                            className={classes.FontClass}
                            value="payment"
                            control={
                              <Radio
                                name="radioName3"
                                classes={{
                                  root: classes.radioController,
                                  checked: classes.checkedRadio
                                }}
                                className={classes.FontClass}
                              />
                            }
                            label="Payment received"
                          />
                          <FormControlLabel
                            className={classes.FontClass}
                            value="credit"
                            control={
                              <Radio
                                name="radioName4"
                                classes={{
                                  root: classes.radioController,
                                  checked: classes.checkedRadio
                                }}
                                className={classes.FontClass}
                              />
                            }
                            label="Credit return" />
                        </RadioGroup>
                      </Grid>
                      <Grid item sm={12} md={12}>
                        <InputLabel className={classes.inputLabel5}>
                          Amount *:
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          name="payment_received"
                          onChange={handleChange}
                          value={values.payment_received}
                          placeholder="e.g. J$100,000"
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            inputProps: {
                              step: 1,
                              min: 0,
                              style: {
                                MozAppearance: "textfield",
                              },
                            },
                          }}
                          className={classes.inputField5}
                          error={handleFormikErrors(errors.payment_received, touched.payment_received)}
                          helperText={handleFormikHelperText(errors.payment_received, touched.payment_received)}
                        />
                      </Grid>
                      <Grid item sm={12} md={12}>
                        <InputLabel className={classes.inputLabel5}>
                          Payment Date*:
                        </InputLabel>
                        <TextField
                         inputProps={{ min: oldestInvoiceDate !== null ? oldestInvoiceDate : ''}}
                          type="date"
                          variant="outlined"
                          fullWidth
                          name="payment_date"
                          placeholder="e.g. July 20, 2023"
                          onChange={handleChange}
                          value={values.payment_date}
                          className={classes.inputField5}
                          error={handleFormikErrors(errors.payment_date, touched.payment_date)}
                          helperText={handleFormikHelperText(errors.payment_date, touched.payment_date)}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        md={12}
                        className={classes.btnContainer5}
                      >
                        <CommonButtonWeb btnText="Save" type="submit" disabled={activeBtn} />
                        <CommonButtonWeb btnText="Add Another Payment" type="submit" disabled={!activeBtn} />
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              wrap="nowrap"
              style={{ width: "100%" }}
            >
              <h4 className={classes.rightTitle}>
                Outstanding Account Balance
              </h4>
              <CommonButtonWeb
                btnText={isBalanceCredit ? `(J$ ${balanceConverter(balance)})` : `J$ ${balanceConverter(balance)}`}
                color={isBalanceCredit ? "#20BB6E" : "#2E2A40"}
                backgroundColor="#FEF1CB"
                width="auto"
              />
            </Grid>
            <div className={classes.tableContainer}>
              <div className="tableHeader">
                <div className="headCell">Recent Payments Received</div>
                <div className="headCell">Payment Date</div>
              </div>
              <div className="tableBody">
                {tableData?.map((obj: any, index: number) => {
                  const value = fieldData?.payment_received_id === obj.id ? fieldData.payment_received : obj.payment_received;
                  return (
                    <div className="tableRow" key={obj.id}>
                      <div className="cell editCell">
                        {obj.payment_type === 'credit' && fieldData.payment_received_id !== obj.id ?
                          <div style={{ color: "#20BB6E" , width:'110px' , overflow:'hidden' , textOverflow:'ellipsis' ,fontSize:'12px' , fontWeight:800}}>(J$ {convertIntoCurrency(value)})</div> :
                          <TextField
                            data-test-id={`onchange-${index}`}
                            name="payment_received"
                            value={value || null}
                            required
                            onChange={(e) => this.handleTableField(e, obj.id)}
                            className="field"
                            InputProps={{
                              inputComponent: NumberFormatCustom as any,
                              inputProps: {
                                step: 1,
                                min: 0,
                                style: {
                                  MozAppearance: "textfield",
                                },
                              },
                            }}
                            disabled={fieldData.payment_received_id !== obj.id}
                          />}
                      </div>
                      <div className="cell dateCell">{obj.payment_date}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
        <CustomSnackbar
          open={this.state.isSnackbarOpen}
          onCloseSnack={this.handleSnackbarClose}
          message={this.state.snackBarMessage}
          severity="error"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ProcessPayment);
// Customizable Area End
